import React from "react";
import { Breadcrumb } from "antd";
import { ImHome } from "react-icons/im";
import Link from "next/link";
import classNames from "classnames";

export default function TBreadcrumb({ routes, color, colorSeparator = "white" }) {
  return (
    <div>
      <div className="  tw-flex tw-gap-[10px] tw-items-start lg:tw-mb-[20px] md:tw-mb-[20px] tw-mb-[10px]">
        <ImHome className="tw-relative tw-top-[-2px]" color="white" fontSize={23} />
        <Breadcrumb
          separator={
            <span style={{ color: colorSeparator }} className={classNames("tw-text-white")}>
              /
            </span>
          }
          className="tw-text-[16px]"
        >
          {routes.map(({ id, name, href, active, colorName }) => (
            <Breadcrumb.Item key={id}>
              <Link href={href}>
                <a>
                  <span
                    className={classNames("tw-font-medium", `${color}`, {
                      "tw-text-white": !active,
                      "!tw-text-red": active,
                    })}
                    style={{ color: colorName ? colorName : "" }}
                  >
                    {name}
                  </span>
                </a>
              </Link>
            </Breadcrumb.Item>
          ))}
        </Breadcrumb>
      </div>
    </div>
  );
}
