/* eslint-disable camelcase */
import { Col, Pagination, Row } from "antd";
import classnames from "classnames";
import { useState } from "react";
import BackgroundImage from "src/assets/images/bg-2.jpg";
import { CardProducts } from "src/components/Home/OurProducts";
import Title from "src/components/Home/Title";
import TBreadcrumb from "src/components/Service/ServiceDetail/TBreadcrumb";
import usePagination from "src/hooks/usePagination";
import LayoutView from "src/layouts/LayoutView";
import { useGetProductsByCategory } from "src/react-query/hooks/product";

const ListCategory = [
  {
    id: 1,
    name: "Hóa chất chăm sóc xe",
    slug: "hoa-chat-cham-soc-xe",
  },
  {
    id: 2,
    name: "Sản phẩm tiện ích",
    slug: "san-pham-tien-ich",
  },
  {
    id: 3,
    name: "Đồ chơi ô tô",
    slug: "do-choi-o-to",
  },
];
const routesBreadcrumb = [
  { id: 1, name: "Trang chủ", href: "#", active: false, separator: true },
  {
    id: 2,
    name: "Sản phẩm",
    href: "#",
    active: true,
    separator: true,
    colorName: "red",
  },
];

export const ButtonCategory = ({ id, name, active, ...props }) => {
  return (
    <button
      key={id}
      className={classnames(
        "tw-px-[24px] tw-py-[8px] tw-text-orange-dark tw-uppercase tw-text-[16px] tw-leading-[22px] tw-border-[1px] tw-border-solid tw-rounded-[5px]",
        {
          "tw-border-white": active,
          "tw-border-black": !active,
        },
      )}
      {...props}
    >
      {name}
    </button>
  );
};

export const customPagination = (page, type) => {
  if (type == "prev" || type == "next") {
    return null;
  }
  return (
    <div className="tw-w-[32px] tw-h-[32px] tw-text-center tw-flex tw-items-center tw-justify-center tw-bg-black tw-text-orange-dark tw-border-[1px] tw-border-solid tw-border-orange-dark tw-rounded-[5px]">
      {page}
    </div>
  );
};

export const Products = () => {
  const { pagination, onChangePage } = usePagination({ page_size: 6, page: 1 });
  const [activeCategory, setActiveCategory] = useState(ListCategory[0]);

  // useGetProductsByCategory
  // const { pagination, onChangePage } = usePagination({ page_size: 6, page: 1 });
  const productCategoriesQuery = useGetProductsByCategory({
    slug: activeCategory?.slug,
    page_size: pagination.page_size,
    page: pagination.page,
  });

  const dataProducts = productCategoriesQuery?.data?.data?.listProductByCategory || [];
  const totalPage = productCategoriesQuery?.data?.data?.total || 1;
  // const loadingServiceCategories = productCategoriesQuery.isLoading;

  const handleChangeTab = (item) => {
    setActiveCategory(item);
  };

  return (
    <LayoutView>
      <div
        style={{
          backgroundImage: `url(${BackgroundImage.src})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <div className="tw-flex tw-justify-center">
          <TBreadcrumb routes={routesBreadcrumb} />
        </div>
        <Title title="Sản phẩm của chúng tôi" subTitle="Autoshine" colorTitle="white" />

        <div className="tw-flex tw-items-center tw-justify-center tw-gap-[50px] tw-mb-[45px]">
          {ListCategory.map((item) => {
            return (
              <ButtonCategory
                key={item.id}
                name={item.name}
                active={activeCategory.id == item.id}
                onClick={() => handleChangeTab(item)}
              />
            );
          })}
        </div>

        <div className="tw-container tw-mx-auto lg:tw-px-[30px] tw-px-[15px] xl:tw-max-w-[1700px] tw-max-w tw-text-black">
          <Row gutter={[15, 40]}>
            {dataProducts.map((item, index) => {
              return (
                <Col xl={8} lg={12} md={24} sm={24} xs={24} key={index}>
                  <CardProducts key={item.id} data={item} />
                </Col>
              );
            })}
          </Row>
        </div>
        {totalPage > pagination?.page_size && (
          <div className="custom-pagination">
            <Pagination
              showLessItems={true}
              total={totalPage}
              current={pagination?.page}
              pageSize={pagination?.page_size | 0}
              onChange={onChangePage}
              itemRender={customPagination}
            />
          </div>
        )}
      </div>
    </LayoutView>
  );
};
