/* eslint-disable camelcase */
import { Col, Pagination, Row, Image } from "antd";
import { useRouter } from "next/router";
import Background from "src/assets/images/background.png";
import Title from "src/components/Home/Title";
import { customPagination } from "src/components/Products/Products";
import { routerConstant } from "src/constants/routerConstant";
import { KHUYEN_MAI } from "src/constants/types";
import usePagination from "src/hooks/usePagination";
import { useGetAllProduct } from "src/react-query/hooks/product";

const contentStyle = {
  display: "flex",
  flexDirection: "column",
  textAlign: "center",
  backgroundImage: `url(${Background.src})`,
};

export const CardProducts = ({ data, type }) => {
  const parseImages = data?.images ? JSON.parse(data?.images) : [];
  const router = useRouter();

  const navigatePage = () => {
    if (!data?.slug) return;
    if (type !== KHUYEN_MAI) {
      return router.push(`${routerConstant.home}/${data?.slug}`);
    }
    if (type === KHUYEN_MAI) {
      return router.push(`${routerConstant.news}/${data?.slug}`);
    }
  };

  return (
    <div className="tw-mx-[10px] tw-cursor-pointer tw-h-full">
      <div className="tw-h-full tw-flex tw-items-center md:tw-flex-row tw-flex-col tw-justify-center tw-bg-white tw-rounded-[8px] tw-px-[24px] tw-py-[20px] tw-gap-[30px]">
        <div className="tw-w-[200px] tw-shrink-0">
          <Image
            src={type !== KHUYEN_MAI ? parseImages?.[0] : data?.background_image}
            alt={type !== KHUYEN_MAI ? data?.sub_title : data?.description}
            width={200}
            height={200}
            preview={false}
            onClick={navigatePage}
            className="tw-max-h-[200px] tw-object-cover tw-w-full tw-h-full"
          />
        </div>
        <div className="tw-text-start tw-flex-1">
          <h3
            className="tw-text-[20px] md:tw-text-[24px] tw-font-extrabold tw-mb-[12px] overFlow-2-line"
            onClick={navigatePage}
          >
            {type !== KHUYEN_MAI ? data?.name : data?.title}
          </h3>
          <p className="tw-text-[16px] md:tw-mb-[20px] tw-mb-[16px] overFlow-2-line">
            {type !== KHUYEN_MAI ? data?.sub_title : data?.description}
          </p>
          {type !== KHUYEN_MAI && (
            <div className="md:tw-text-[24px] tw-text-[22px] tw-font-extrabold overFlow-2-line">
              {Intl.NumberFormat("vi-VN", { style: "currency", currency: "VND" }).format(
                data?.price,
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export const CardProductsFake = ({ item }) => {
  const router = useRouter();

  const navigateProduct = () => {
    if (!item?.slug) return;
    router.push(`${routerConstant.home}/${item?.slug}`);
  };

  return (
    <div className="tw-mx-[10px] tw-cursor-pointer">
      <div className="tw-flex tw-items-center md:tw-flex-row tw-flex-col tw-justify-center tw-bg-white tw-rounded-[8px] tw-px-[24px] tw-py-[30px] tw-gap-[30px]">
        <Image
          src={item.image}
          alt={item.title}
          width={280}
          height={260}
          layout="intrinsic"
          objectFit="contain"
          objectPosition="center"
          onClick={navigateProduct}
        />
        <div className="tw-text-start">
          <h3
            className="tw-text-[20px] md:tw-text-[24px] tw-font-extrabold tw-mb-[12px]"
            onClick={navigateProduct}
          >
            {item?.name}
          </h3>
          <p className="tw-text-[16px] md:tw-mb-[20px] tw-mb-[16px] overFlow-2-line">
            {item?.sub_title}
          </p>
          <div className="md:tw-text-[24px] tw-text-[22px] tw-font-extrabold">
            {Intl.NumberFormat("vi-VN", { style: "currency", currency: "VND" }).format(item.price)}
          </div>
        </div>
      </div>
    </div>
  );
};

const paginationDefaults = { page_size: 9, page: 1 };

export default function OurProducts() {
  const { pagination, onChangePage } = usePagination(paginationDefaults);

  const productQuery = useGetAllProduct({
    page_size: pagination?.page_size,
    page: pagination?.page,
  });

  const dataProducts = productQuery?.data?.data?.products || [];
  const totalPage = productQuery?.data?.data?.total || 1;

  return (
    <div style={contentStyle} className="tw-bg-no-repeat tw-bg-cover">
      <div className="tw-container tw-mx-auto lg:tw-py-[140px] md:tw-py-[100px] tw-py-[60px]  tw-px-[20px]">
        <div>
          <Title
            title="Sản phẩm của chúng tôi"
            subTitle="AUTOSHINE"
            colorSubTitle="#e65613"
            colorTitle="white"
          />
        </div>
        <div>
          <Row gutter={[20, 20]}>
            {dataProducts.map((item) => {
              return (
                <Col key={item.id} md={12} xl={8}>
                  <CardProducts data={item} />
                </Col>
              );
            })}
          </Row>
        </div>
        {totalPage > paginationDefaults.page_size && (
          <div className="custom-pagination">
            <Pagination
              showLessItems={true}
              total={totalPage}
              current={pagination?.page}
              pageSize={pagination?.page_size | 0}
              onChange={onChangePage}
              itemRender={customPagination}
            />
          </div>
        )}
      </div>
    </div>
  );
}
