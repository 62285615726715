/* eslint-disable camelcase */
import { useRouter } from "next/router";
import { useEffect, useState } from "react";

const paginationDefaults = { page_size: 10, page: 1 };

export default function usePagination(paging) {
  const router = useRouter();
  const { page_size, page } = router.query;
  const [pagination, setPagination] = useState(paging);

  const onChangePage = (page, size) => {
    const pathname = router?.asPath.split("?")?.[0];
    router.push(
      {
        pathname,
        query: {
          page,
          page_size: size,
        },
      },
      undefined,
      { shallow: true },
    );
  };

  useEffect(() => {
    if (page_size) {
      setPagination((pre) => ({ ...pre, page_size: Number(page_size) }));
    } else {
      setPagination((pre) => ({
        ...pre,
        page_size: paging?.page_size || paginationDefaults.page_size,
      }));
    }

    if (page) {
      setPagination((pre) => ({ ...pre, page: Number(page) }));
    } else {
      setPagination((pre) => ({ ...pre, page: paging?.page || paginationDefaults.page }));
    }
  }, [page_size, page]);

  return { onChangePage, pagination, setPagination };
}
