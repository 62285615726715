/* eslint-disable @next/next/no-img-element */
import PartnerImage1 from "src/assets/images/partner1.png";
import PartnerImage2 from "src/assets/images/partner2.png";
import PartnerImage3 from "src/assets/images/partner3.png";
import PartnerImage4 from "src/assets/images/partner4.png";
import PartnerImage5 from "src/assets/images/partner5.png";
import PartnerImage6 from "src/assets/images/partner6.png";
import PartnerImage7 from "src/assets/images/partner7.png";

const PartnerList = [
  { id: 1, urlImage: PartnerImage1.src },
  { id: 2, urlImage: PartnerImage2.src },
  { id: 3, urlImage: PartnerImage3.src },
  { id: 4, urlImage: PartnerImage4.src },
  { id: 5, urlImage: PartnerImage5.src },
  { id: 6, urlImage: PartnerImage6.src },
  { id: 7, urlImage: PartnerImage7.src },
];
const PartnerItem = ({ urlImage }) => {
  return (
    <div className="tw-mb-[20px]">
      <img
        className="tw-object-cover tw-h-[125px] tw-mx-[20px]"
        src={urlImage}
        alt="partner"
        width={125}
        height={125}
      />
    </div>
  );
};

export default function Partner() {
  return (
    <div className="tw-flex tw-justify-center tw-items-center tw-px-[20px] tw-space-x-[12px] tw-bg-[#f2f2f2] tw-flex-wrap tw-py-[50px]">
      {PartnerList.map(({ id, urlImage }) => (
        <PartnerItem key={id} urlImage={urlImage} />
      ))}
    </div>
  );
}
