import classNames from "classnames";

export default function Title({
  title,
  subTitle,
  colorTitle = "#bd1300",
  colorSubTitle = "#bd1300",
  className = "",
  showDividerLeft = true,
  showDividerRight = true,
  styleTitle = {},
  subTitleClass,
  titleClass,
}) {
  return (
    <div
      className={classNames(
        "tw-flex tw-flex-col tw-items-center tw-mb-[40px] md:tw-mb-[65px]",
        className,
      )}
    >
      <div
        className={classNames("tw-name tw-uppercase tw-flex tw-items-center")}
        style={{ color: colorSubTitle }}
      >
        {showDividerLeft && (
          <div style={{ background: colorSubTitle }} className="tw-h-[1px] tw-w-[60px]" />
        )}
        <h4
          style={{ color: colorSubTitle }}
          className={classNames("tw-mx-[20px] tw-text-[16px] ", `${subTitleClass}`)}
        >
          {subTitle}
        </h4>
        {showDividerRight && (
          <div style={{ background: colorSubTitle }} className="tw-h-[1px] tw-w-[60px] " />
        )}
      </div>

      <div
        style={{ color: colorTitle, ...styleTitle }}
        className={classNames(
          "tw-text-red tw-text-[46px] tw-leading-[46px]  md:tw-text-[56px] md:tw-leading-[56px]  tw-font-medium tw-mt-[15px] md:tw-mt-[20px]",
          `${titleClass}`,
        )}
      >
        {title}
      </div>
    </div>
  );
}
